import React, { memo } from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
// sections for this page
// import Grid from '@material-ui/core/Grid';

// import { CardContent } from '@material-ui/core';
// import { Typography } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';

import ScrollButton from '../components/ScrollButton';
// import { Card } from "reactstrap";

import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const AnalysisPage = memo(() => {
  const { backgroundImage123 } = useStaticQuery(
    graphql`
      query {
        backgroundImage123: file(relativePath: { eq: "surge.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 70, webpOptions: { quality: 100 })
          }
        }
      }
    `
  );
  const image = getImage(backgroundImage123);

  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      className="masthead3"
      placeholder="BLURRED"
    >
      <Layout fullMenu>
        <Seo
          title="Business Analysis Consultancy"
          description="Agility transformations need you; we are here to partner in your success. We help by analysing the current state and helping create the three horizon strategic roadmap. Once complete we are here with you, one iteration at a time."
        />
        <ScrollButton />
        <article id="main">
          <header>
            <h2>BUSINESS ARCHITECTURE AND ANALYSIS</h2>
            <p>GIVING YOU CLARITY IN A COMPLEX ENVIRONMENT </p>
          </header>
          <section className="wrapper style5">
            <div className="inner">
              <section>
                <header>
                  <h4>OUR CONSULTANTS ARE PROBLEM SOLVERS</h4>
                  <p>
                    Are you struggling to deliver quality results, do you have
                    challenges designing and implementing business process or
                    eliciting, documenting and implementing business needs. Are
                    you having problems translating business needs into
                    technology solutions. We have a team of business analysis
                    experts who specialises in requirement engineering and
                    implementation, business process modelling, and project
                    delivery. We help companies develop business solutions to
                    obtain greater value for themselves and their customers.
                  </p>
                </header>

                <p>
                  We work with startups right through to central government.
                  Analysis can be the key between success and failure of a
                  product or service.
                </p>

                <div className="row">
                  <div className="col-6 col-12-medium">
                    <ul>
                      <li>
                        Creating Business Cases to enable the right solution
                      </li>
                      <li>Requirement Engineering and backlog creation</li>
                      <li>Mapping the process for efficiency</li>
                      <li>Researching the customer journey for productivity</li>
                    </ul>
                  </div>
                </div>
                <p>
                  The accelerating pace of technology change and increasingly
                  complexity of business environments can create misperception
                  and miscommunication that pushes a business’s model
                  increasingly away from its goals. The result is customer
                  losses, missed opportunities and running up spending on failed
                  projects.
                </p>

                <p>How can we help?</p>
              </section>

              <section>
                <ul className="features3">
                  <li>
                    <a href="/business-analysts/">
                      <StaticImage
                        src="../assets/images/business_agility_current_state.jpg"
                        alt="current state analysis"
                        placeholder="BLURRED"
                        quality="80"
                      />
                      <h4>
                        Business <br></br>Analysts
                      </h4>
                      <p>
                        If you are in need for the right fit business analyst
                        for your project, we can match your need. We have
                        specialists in process re-engineering, business case
                        development and business architecture.
                      </p>
                    </a>
                  </li>
                  <li>
                    <a href="/business-analysts/jobs-to-be-done/">
                      <StaticImage
                        src="../assets/images/agility_leadership_development.png"
                        alt="Leadership Development"
                        placeholder="BLURRED"
                        quality="80"
                        aspectRatio={4 / 3}
                      />
                      <h4>
                        JOBS to be <br></br>Done
                      </h4>
                      <p>
                        Surge provides a framework for defining, categorizing,
                        capturing, and organizing all your customer needs,
                        prioritised objectively based on the value KPIs you have
                        in your organisation.
                      </p>
                    </a>
                  </li>
                  <li>
                    <a href="/business-analysts/business-process-automation/">
                      <StaticImage
                        src="../assets/images/project.jpg"
                        alt="Strategic Roadmaps"
                        placeholder="BLURRED"
                        quality="80"
                        aspectRatio={4 / 3}
                      />
                      <h4>
                        Business Process <br></br>Automation
                      </h4>
                      <p>
                        Technology automation of complex business processes. It
                        can streamline a business, achieve digital
                        transformation, increase quality, improve service
                        delivery and contain costs.
                      </p>
                    </a>
                  </li>
                </ul>
              </section>

              <section id="two" className="wrapper alt style3">
                <section className="spotlight">
                  <div className="image">
                    <StaticImage
                      src="../assets/images/paul.png"
                      alt="Paul Benn"
                      placeholder="BLURRED"
                      quality="80"
                    />
                  </div>
                  <div className="content">
                    <h2>
                      Business Analysis
                      <br />
                    </h2>
                    <p>
                      "As teams and organisations move to agile ways of working,
                      the needs for business analyst skills is increasing, not
                      decreasing. From the start of a project a business analyst
                      will use design thinking to get to the true customer
                      needs, measuring the hypothesed benefit realisation,
                      increase transparency through stakeholder analysis and
                      understand the feasibility of the outcome; to the project
                      delivery where a business analyst will ensure the needs of
                      the customers are always in front of the team, analysing
                      problems that arise and advising on solutions that still
                      meet the project return on investment guidelines. Surge
                      business analysts know agile, they understand multiple
                      delivery models but are agnostic to the chosen delivery
                      model, they focus on the customer, the outcome and
                      partnering with leaders to maximise competitive
                      advantage."
                    </p>
                    <a
                      href="https://www.linkedin.com/in/pbennct/"
                      className="button primary"
                    >
                      Contact Paul
                    </a>
                  </div>
                </section>
              </section>
            </div>
          </section>
        </article>
      </Layout>
    </BackgroundImage>
  );
});

export default AnalysisPage;
